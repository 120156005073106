import { HTMLProps } from "react";
import { buttonStyle } from "./style";


export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: "submit" | "reset" | "button" | undefined;
  uppercase?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  uppercase = true,
  ...props
}) => {
  return (
    <button
      className={className ? `${className} ${buttonStyle}${uppercase ? " uppercase" : ""}` : buttonStyle}
      {...props}
    >{children}</button>
  )
}
