import { HTMLProps } from "react";
import { inputContainer, inputStyle, iconStyle, inputDefault, inputUnderline } from "shared/ui/InputWithIcon/style";

const enum InputVersions {
  default,
  underline,
}

const stylesByVersion = {
  0: inputDefault,
  1: inputUnderline
}

interface Props extends HTMLProps<HTMLInputElement> {
  icon?: any;
  version?: InputVersions;
}

export const InputWithIcon: React.FC<Props> = ({
  icon,
  className,
  version = InputVersions.default,
  ...props
}) => {
  return (
    <div className={className ? `${className} ${inputContainer}` : inputContainer}>
      <input className={`${inputStyle} ${stylesByVersion[version]}`} {...props} />
      {icon && (
        <span className={iconStyle}>{icon}</span>
      )}
    </div>
  )
}
