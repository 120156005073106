import { Button } from "../Button"
import "./index.scss"
import { ReactNode, useCallback, useRef } from "react"
export interface IModalUi {
	isActive: boolean
	onClose: () => void
	children: ReactNode
	borderRadius?: string
	variant: "CENTER" | "RIGHT" | "LEFT"
	className?: string
	isDangerous?: boolean
}

const ModalUi = ({ isActive, onClose, children, variant, className, isDangerous }: IModalUi) => {
	const containerRef = useRef(null)
	if (!isActive) {
		return null
	}
	// for all modals will right
	const modalPosition = isDangerous ? "CENTER" : "RIGHT"
	const setScroll = () => {
		//@ts-ignore
		if (isDangerous) {
			return false
		}
		return true

	}
	return (
		<div className={`modal-black-window ${modalPosition} `} onClick={onClose}>
			<div className={`main-modal ${className}`} onClick={(e) => e.stopPropagation()}>
				<div className="relative">
					<div className="close-icon" onClick={onClose} >X</div>
				</div>
				<div className={`max-h-[800px] ${setScroll() ? 'scrollable' : ''}`} ref={containerRef}>
					{children}
				</div>
			</div>
		</div>
	)
}
export default ModalUi
