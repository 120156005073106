
import Header from 'Widgets/Headar/Header';
import { useUnit } from 'effector-react';
import { $loginState, logoutFx } from 'entities/login/model/tLoginFormModel';
import { $user } from 'entities/user/model';
import Login from 'pages/Login';
import { Navigate, useNavigate } from 'react-router-dom'
import { WAVEUM_LOGIN } from 'shared/constants/generic';
import { getCookieByKey, getLang, getLangPrefix, parseJwtData } from 'shared/helpers/genericHelper'
import { getTokenExpirationDate, sessionStorageSync } from 'shared/helpers/storageHelper';
import OldLoginSidebar from 'shared/sidebar';
import Footer from 'shared/ui/Footer';


const PrivateWrapper = ({ children, isFullPrivate = true }: any) => {
  const { isAuthenticated } = useUnit($loginState);
  const privateToken = getCookieByKey("privateToken") || sessionStorageSync.privateToken
  const expTime = getTokenExpirationDate(privateToken);
  const isExpired = new Date().getTime() >= expTime;
  if (isExpired && privateToken) {
    window.open(`${WAVEUM_LOGIN}${getLangPrefix()}`, "_self")
    return <></>
  }
  if (!isAuthenticated) return <Login />;
  return <>
    <OldLoginSidebar
    // userBrandApp={userBrandApp}
    // logoutStart={logoutStart}
    // brandApp={brandApp}
    ></OldLoginSidebar>
    <Header >
      {children}
    </Header>
    <Footer />
  </>
}

export default PrivateWrapper