import { createStore, createEffect, createEvent } from 'effector';
import { WAVEUM_LOGIN } from 'shared/constants/generic';
import { ApiError } from 'shared/exceptions/apiError';
import { eraseCookie, getLangPrefix } from 'shared/helpers/genericHelper';
import { getTokenExpirationDate, sessionStorageSync } from 'shared/helpers/storageHelper';

// Interface for LoginState
interface LoginState {
	error: null | string;
	loginData: any;
	isAuthenticated: boolean;
}

export const $loginState = createStore<LoginState>({
	error: null,
	loginData: null,
	isAuthenticated: Boolean(sessionStorageSync.privateToken),
});
export const clearLoginState = createEvent()
export const loginFx = createEffect(async (privateToken: string) => {
	const expTime = getTokenExpirationDate(privateToken);
	const isExpired = new Date().getTime() >= expTime;
	console.log('worked login')
	try {
		sessionStorageSync.privateToken = privateToken;
		console.log(privateToken, "========== privateToken ===============")
		eraseCookie('privateToken');
		console.log(isExpired, 'isExpired =====')
		// if (isExpired) {
		// 	window.open(`${WAVEUM_LOGIN}${getLangPrefix()}`, "_self")
		// 	throw new Error("error")
		// }
		return privateToken

	} catch (error) {
		throw ApiError.UnauthorizedError()
	}

});

$loginState
	.on(loginFx, (state) => ({ ...state, error: null, loginData: null }))
	.on(loginFx.done, (state, { result }) => {
		console.log("worked done", result)
		return {
			...state,
			error: null,
			loginData: result,
			isAuthenticated: true,
		}
	})
	.on(loginFx.fail, (state, { error }) => {
		return {
			...state,
			error: error.message,
			loginData: null,
			isAuthenticated: false,
		}
	}).reset(clearLoginState)

export const logoutFx = createEffect(() => {
	sessionStorage.clear()
	clearLoginState()
	localStorage.clear()
	// window.open(`${WAVEUM_LOGIN}${getLangPrefix()}`, "_self")
})