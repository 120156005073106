import { BaseApi } from 'shared/api/base'
import { BASE_URL } from 'shared/constants/generic';
import { sessionStorageSync } from 'shared/helpers/storageHelper';

export const fetchUser = () => {
  const http = new BaseApi('/api');

  return http.get(`/account?cacheBuster=${+new Date()}`);
}

export const fetchUserUpdate = (body: any) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${sessionStorageSync.privateToken}`)
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow"
  };
  //@ts-ignore
  fetch(`${BASE_URL}/api/account/update_profile?cacheBuster=1712301387262`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
}
export const fetchChangePassword = (body: any) => {
  const http = new BaseApi("/api")
  return http.post(`/user/change-password?cacheBuster=${+new Date()}`, body)
}