import { updatePaths } from "entities/menu/model/menu";
import PrivateWrapper from "pages/PrivateWrapper";
import { LazyExoticComponent, lazy } from "react"
import { Navigate, RouteObject } from "react-router"
import { getLangPrefix } from "shared/helpers/genericHelper"

const Login = lazy(() => import("pages/Login"));
const Home = lazy(() => import("pages/Home"));
const WebSales = lazy(() => import("pages/WebSales"));
const StoreSales = lazy(() => import("pages/Store/Sales"));
const StoreDrawer = lazy(() => import("pages/Store/Drawer"));
const StoreProducts = lazy(() => import("pages/Store/Products"));
const LogisticSellCoverage = lazy(() => import("pages/LogisticSellCoverage"));
const ProductsEcommerce = lazy(() => import("pages/Products/Ecommerce"));
const ProductsStore = lazy(() => import("pages/Products/Store"));
const ProductsOutOfStocks = lazy(() => import("pages/Products/OutOfStocks"));
const ProductsUnknown = lazy(() => import("pages/Products/Unknown"));
const ProductsImport = lazy(() => import("pages/Products/Import"))
const StoreTickets = lazy(() => import("pages/Store/Tickets"));
const StoreVouchers = lazy(() => import("pages/Store/Vouchers"));
const StorePrepayment = lazy(() => import("pages/Store/Prepayment"));
const PromotionsStores = lazy(() => import("pages/Promotions/Stores"));
const PromotionsStoreClusters = lazy(() => import("pages/Promotions/StoreClusters"));
const PromotionsPromotions = lazy(() => import("pages/Promotions/Promotions"));
const PromotionsProductclusters = lazy(() => import("pages/Promotions/ProductClusters"));
const PromotionsRules = lazy(() => import("pages/Promotions/Rules"));
const PromotionsCoupons = lazy(() => import("pages/Promotions/Coupons"));
const StoreGiftCards = lazy(() => import("pages/Store/GiftCards"));
const ContentBrands = lazy(() => import("pages/Content/Brands"));
const ProductsOptions = lazy(() => import("pages/Products/Options"));
const ProductsSurveys = lazy(() => import("pages/Products/Surveys"));
const ContentCatalogs = lazy(() => import("pages/Content/Catalogs"));
const ContentStaticPages = lazy(() => import("pages/Content/StaticPages"));
const ContentProductPages = lazy(() => import("pages/Content/ProductPages"));
const ContentMenuBar = lazy(() => import("pages/Content/MenuBar"))
const ContentGrids = lazy(() => import("pages/Content/Grids"))
const ContentGridDetails = lazy(() => import("pages/Content/GridDetails"))
const LogisticInventoryrequest = lazy(() => import("pages/Logistic/InventoryRequest"))
const LogisticInventory = lazy(() => import("pages/Logistic/Inventory"))
const LogisticDelivery = lazy(() => import("pages/Logistic/Delivery"))
const LogisticTranfers = lazy(() => import("pages/Logistic/Transfers"))
const LogisticStock = lazy(() => import("pages/Logistic/Stock"))
const LogisticTransit = lazy(() => import("pages/Logistic/Transit"))
const ClientsStore = lazy(() => import("pages/Client/Store"))
const ClientsWEB = lazy(() => import("pages/Client/WEB"))
const ClientsEmployees = lazy(() => import("pages/Client/Employees"))
const AdminManageUsers = lazy(() => import("pages/Admin/ManageUsers"))
const CustomerServicesTickest = lazy(() => import("pages/CustomerServices/Tickets"))
const ProductDetails = lazy(() => import("pages/Products/Details"))
const ContentProductPagesDetails = lazy(() => import("pages/Content/ProductPages/Details/ContentproductPagesDetails"))
const ContentProductPagesDetailsById = lazy(() => import("pages/Content/ProductPages/Details/DetailsById"))
const ContentMenuBarDetailsDashboard = lazy(() => import("pages/Content/MenuBar/Details/ContentMenuBarDetailsDashboard"))
const ProductsClusterDetailsPage = lazy(() => import("pages/Promotions/ProductClusters/DetailsPage/ProductsClusterDetailsPage"))
const PromotionsPromotionsDetails = lazy(() => import("pages/Promotions/Promotions/Details/PromotionsDetails"))
const WholeSaleOrders = lazy(() => import("pages/Wholesale/Order"))
const WholeSaleClients = lazy(() => import("pages/Wholesale/Clients"))
const StoreInvoices = lazy(() => import("pages/Store/Invoices"))
const AIAssistant = lazy(() => import("pages/AiAssistant"))
export type RouteStoreItem = {
  path: string;
  data?: Record<string, number | string | boolean>;
}

const langPrefix = '/:ln'

type RouteComponent = {
  component: LazyExoticComponent<any>;
  isPrivate: boolean
  props?: any;
}

const componentWithKeys: Record<string, RouteComponent> = {
  login: { component: Login, isPrivate: true },
  home: { component: Home, isPrivate: true },
  webSales: { component: WebSales, isPrivate: true },
  storeSales: { component: StoreSales, isPrivate: true },
  storeProducts: { component: StoreProducts, isPrivate: true },
  storeDrawer: { component: StoreDrawer, isPrivate: true },
  productAnalytics: { component: StoreProducts, isPrivate: true },
  logisticSellCoverage: { component: LogisticSellCoverage, isPrivate: true },
  storeTickets: { component: StoreTickets, isPrivate: true },
  productsEcommerce: { component: ProductsEcommerce, isPrivate: true },
  productsStore: { component: ProductsStore, isPrivate: true },
  productsOutOfStocks: { component: ProductsOutOfStocks, isPrivate: true },
  productsUnknown: { component: ProductsUnknown, isPrivate: true },
  productsImport: { component: ProductsImport, isPrivate: true },
  storeVouchers: { component: StoreVouchers, isPrivate: true },
  storePrepayment: { component: StorePrepayment, isPrivate: true },
  promotionsStores: { component: PromotionsStores, isPrivate: true },
  promotionsStoreClusters: { component: PromotionsStoreClusters, isPrivate: true },
  promotionsPromotions: { component: PromotionsPromotions, isPrivate: true },
  promotionsProductclusters: { component: PromotionsProductclusters, isPrivate: true },
  promotionsRules: { component: PromotionsRules, isPrivate: true },
  promotionsCoupons: { component: PromotionsCoupons, isPrivate: true },
  contentBrands: { component: ContentBrands, isPrivate: true },
  contentCatalogs: { component: ContentCatalogs, isPrivate: true },
  storeGiftCards: { component: StoreGiftCards, isPrivate: true },
  productsOptions: { component: ProductsOptions, isPrivate: true },
  productsSurveys: { component: ProductsSurveys, isPrivate: true },
  contentStaticPages: { component: ContentStaticPages, isPrivate: true },
  contentProductPages: { component: ContentProductPages, isPrivate: true },
  contentMenuBar: { component: ContentMenuBar, isPrivate: true },
  contentGrids: { component: ContentGrids, isPrivate: true },
  contentGridsDetails: { component: ContentGridDetails, isPrivate: true },
  logisticInventoryrequest: { component: LogisticInventoryrequest, isPrivate: true },
  logisticInventory: { component: LogisticInventory, isPrivate: true },
  logisticDelivery: { component: LogisticDelivery, isPrivate: true },
  logisticTransfer: { component: LogisticTranfers, isPrivate: true },
  logisticStock: { component: LogisticStock, isPrivate: true },
  logisticTransit: { component: LogisticTransit, isPrivate: true },
  clientsStore: { component: ClientsStore, isPrivate: true },
  clientsWeb: { component: ClientsWEB, isPrivate: true },
  clientsEmployees: { component: ClientsEmployees, isPrivate: true },
  manageUsers: { component: AdminManageUsers, isPrivate: true },
  customerServiceTickets: { component: CustomerServicesTickest, isPrivate: true },
  productsEcommerceDetails: { component: ProductDetails, isPrivate: true },
  contentProductPagesDetails: { component: ContentProductPagesDetails, isPrivate: true },
  contentProductPagesDetailsById: { component: ProductDetails, isPrivate: true },
  contentMenuBarDetails: { component: ContentMenuBarDetailsDashboard, isPrivate: true },
  promotionsProductClustersDetails: { component: ProductsClusterDetailsPage, isPrivate: true },
  promotionsPromotionsDetails: { component: PromotionsPromotionsDetails, isPrivate: true },
  productsStoreDetails: { component: ProductDetails, isPrivate: true },
  productsOutofstocksDetails: { component: ProductDetails, isPrivate: true },
  wholesaleOrders: { component: WholeSaleOrders, isPrivate: true },
  wholesaleClients: { component: WholeSaleClients, isPrivate: true },
  storeInvoices: { component: StoreInvoices, isPrivate: true },
  aiAssistant: { component: AIAssistant, isPrivate: true }
}

const defaultRoutes: RouteObject[] = [];

const menuPaths: Record<string, RouteStoreItem> = {};

export const useGenerateRoutes = (menu: Record<string, string>[]) => {
  const setRoutes = () => {
    defaultRoutes.push({
      path: "/",
      element: <Navigate to={getLangPrefix()} replace />,
    })

    menu.forEach((menuItem) => {
      const Component = componentWithKeys[menuItem.key].component;
      const isPrivate = componentWithKeys[menuItem.key].isPrivate
      menuPaths[menuItem.key] = {
        path: getLangPrefix() + menuItem.canonicalUrl,
        data: menuItem
      };

      defaultRoutes.push({
        path: `${langPrefix}${menuItem.canonicalUrl}`,
        element: isPrivate ? <PrivateWrapper> <Component /></PrivateWrapper> : null
      })
    })

    defaultRoutes.push({
      path: '*',
      element: <Navigate to={getLangPrefix() + "/web-orders"} replace />,
    });

    updatePaths(menuPaths);
  }

  if (!defaultRoutes.length && menu.length) {
    setRoutes()
  }

  return { defaultRoutes }
}
