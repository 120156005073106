import { loginFx } from "entities/login/model/tLoginFormModel";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { WAVEUM_LOGIN } from "shared/constants/generic";
import { getCookieByKey, getLangPrefix } from "shared/helpers/genericHelper";
import { sessionStorageSync } from "shared/helpers/storageHelper";

const Login = () => {
  useEffect(() => {
    const privateToken = getCookieByKey("privateToken") || sessionStorageSync.privateToken
    if (privateToken) {
      loginFx(privateToken);
      // <Navigate to={`${getLangPrefix()}/`} replace />
    } else {
      window.open(`${WAVEUM_LOGIN}${getLangPrefix()}`, "_self")
    }
  }, [])
  return <></>
};

export default Login;
