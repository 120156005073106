import CryptoJS from 'crypto-js';
import i18next from 'i18next';
import { localStorageSync } from './storageHelper';
import { WEEK_MILISECONDS } from 'shared/constants/time';
import { InventoryRequestStatus } from 'features/logistic/effects/logisticInventory';

export const callbackSkeleton = () => { }

export const getCryptoUsername = (username: string) => {
	return CryptoJS.SHA256(username).toString()
}

export const getVaultBoxLocation = (uid: string, password: string) => {
	return CryptoJS.AES.encrypt(
		uid,
		CryptoJS.enc.Hex.parse(CryptoJS.SHA256(password).toString()),
		{ iv: CryptoJS.enc.Hex.parse(password) },
	);
};

export const getLang = () => {
	let lang = localStorage.getItem("language")?.split("_")
	return lang?.[0] || i18next.language
};

export const getApiPrefix = () => {
	const lang = getLang();
	return `${lang}_${lang.toUpperCase()}`;
};
export const getLangPrefix = () => {
	return `/${getLang()}`;
}

export const debounce = (fn: any, timeout: any) => {
	let timer: any;
	return (...args: any) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			fn.apply(this, args)
		}, timeout)
	}
}

export const parseJwtData = (token: string) => {
	try {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const data = JSON.parse(atob(base64));

		return {
			exp: data.exp * 1000,
			token
		}
	} catch (error) {
		return null;
	}
}

export const getCookieByKey = (selector: string) => {
	const cookies = document.cookie.split(";").reduce((acc: Record<string, any>, item) => {
		const [key, value] = item.split("=");
		acc[`${key}`.trim()] = `${value}`.trim();

		return acc;
	}, {});

	return cookies[selector];
}

export const eraseCookie = (name: string) => {

	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	console.log(document.cookie, "cookie")
}

export const urlToLabel = (url?: string): string => {

	if (url) {
		return url.split("-")[0].toUpperCase()
	}
	return ''
}

export const urlToBreadCrumbPath = (url?: string) => {
	const langSlice = localStorage?.getItem('language')?.split ? `/${localStorage?.getItem('language')?.split('_')[0]}` : `/${i18next.language}`
	if (url) {
		let deleteLang = ''
		if (url.includes(`${langSlice}`)) {
			deleteLang = url.replace(`${langSlice}/`, '')

		}
		return deleteLang.replace("-", " > ").toUpperCase().replaceAll("/", " > ").replaceAll("_", " ")
	}
	return ''
}

export const toDateFormat = (date: any, withTime: boolean = false) => {
	try {
		return new Intl.DateTimeFormat(`${localStorageSync.language.toLowerCase()}-${localStorageSync.language}`, {
			day: "numeric",
			month: "numeric",
			year: "numeric",
			...(withTime && { hour: "numeric" }),
			...(withTime && { minute: "numeric" })
		}).format(date);
	} catch (error) {
		return null;
	}
}

export const getInterval = (interval: number) => {
	return { fromDate: new Date().getTime() - interval, toDate: new Date().getTime() }
}

export const getOneWeekInterval = () => {
	return getInterval(WEEK_MILISECONDS)
}

export async function readFileAsBase64(file: File, callback: (params: any) => {}) {
	const reader = new FileReader();
	reader.onloadend = () => {
		callback(reader.result);
	};
	reader.onerror = (error) => {
		console.error('Error reading file:', error);
	};
	reader.readAsDataURL(file);
}

export const dateWithMont = (date: any, withTime: boolean = false, weekday?: boolean) => {
	try {
		return new Intl.DateTimeFormat("en-US", {
			...(weekday && { weekday: "short", }),
			day: "numeric",
			month: "short",
			year: "numeric",
			...(withTime && { hour: "numeric" }),
			...(withTime && { minute: "numeric" })
		}).format(date);
	} catch (error) {
		return null;
	}
}
export const dateForCSV = (date: any, withTime: boolean = false, weekday = true) => {
	return dateWithMont(date, withTime, weekday)?.replaceAll(",", '')
}
export const dashBoardDateFormat = (date: any, withTime: boolean = true) => {
	try {
		return new Intl.DateTimeFormat('en-US', {
			day: "numeric",
			month: "short",
			year: "numeric",
			hour12: true,
			...(withTime && { hour: "numeric" }),
			...(withTime && { minute: "numeric" })
		}).format(date);
	} catch (error) {
		return null;
	}
}
export const splitSnaceCaseToEmptyChar = (str: string) => {
	return str.split("_").join(" ")
}

export const updateNestedObjectPropertyValue = (nestedProp: Record<string, any>, obj: Record<string, any>, globalObj = obj, current: any): any => {
	const properties = nestedProp?.name?.includes(".") ? nestedProp?.name?.split('.') : nestedProp?.name
	if (!Array.isArray(properties)) {
		if (current) {
			return { ...globalObj, [current]: { ...obj, [properties]: nestedProp.value } }
		}
		else {
			return { ...globalObj, [properties]: nestedProp.value }
		}
	}
	for (let i = 0; i < properties.length; i++) {
		if (obj.hasOwnProperty(properties[i].trim())) {
			if (typeof obj[properties[i].trim()] === "object") {
				return updateNestedObjectPropertyValue({ name: properties[i + 1], value: nestedProp.value }, obj[properties[i]], globalObj, properties[i])
			}
		}
	}
	return null
}

export const objectToFormData = (obj: Record<string, any>) => {
	const formData = new FormData()
	for (let key in obj) {
		formData.append(key, obj[key])
	}
	return formData
}

export const isEmail = (str: string) => {
	const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
	return emailRegex.test(str)
}

export const setButtonStyles = (requestById: Record<string, any>, logisticStatus: string) => {
	if (logisticStatus !== "COM") {
		return {
			backgroundColorSave: "grey",
			backgroundColorSend: "grey",
			isDisabled: true
		}
	}
	if (logisticStatus === "COM") {
		return {
			backgroundColorSave: "#153b5e",
			backgroundColorSend: "red",
			isDisabled: false
		}
	}
	return {
		backgroundColorSave: "grey",
		backgroundColorSend: "grey",
		isDisabled: true
	}
}
export const setDisableForFailure = (requestById: any) => {
	if (requestById?.status === InventoryRequestStatus.FAILURE && !requestById?.message?.includes("Data are not")) {
		return "grey"
	}
	return ""
}
export const dateWithoutTime = (date: any) => {
	try {
		return new Intl.DateTimeFormat('en-US', {
			day: "numeric",
			month: "short",
			year: "numeric",
		}).format(date);
	} catch (error) {
		return new Date()
	}
}


export const dateTime = (date: any, withTime: boolean = true, hour12 = true) => {
	try {
		return new Intl.DateTimeFormat('en-US', {
			...(withTime && { hour: "numeric" }),
			...(withTime && { minute: "numeric" }),
			hour12: hour12
		}).format(date);
	} catch (error) {
		return null;
	}
}

export const formatPrice = (number: number) => {
	if (typeof number === 'number') {
		return Math.ceil(number).toFixed(2)
	}
	return number || ''

}

export const subtractTimestampsFormatted = (timestamp1: number, timestamp2: number) => {
	let difference = Math.abs(timestamp1 - timestamp2);
	let differenceInHours = Math.floor(difference / (1000 * 60 * 60));
	let differenceInMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
	let formattedDifference = `${String(differenceInHours).padStart(2, '0')}h${String(differenceInMinutes).padStart(2, '0')}`;
	return formattedDifference;
}

export const checkPropertyFromArray = (obj: Object, arr: string[]) => {
	for (let key of arr) {
		if (!obj.hasOwnProperty(key)) {
			return false
		}
	}
	return true
}


export function sliceMenusPathManual(path: string | undefined) {
	if (!path) {
		return null
	}
	const segments = path.split('/');
	for (const segment of segments) {
		if (segment.startsWith('menus-')) {
			return `/${segment}/`;
		}
	}
	return null;
}


export const getMenuIdFromSegment = (segment: string | null) => {
	if (!segment) {
		return null
	}
	const menuId = segment.split('/menus-')
	return menuId.filter((item: string) => item)[0].replaceAll("/", "")
}

export const joinMenubars = (menuBar: any[] = [], sub1: any[] = [], sub2: any[] = []) => {
	let joined = [...(menuBar && menuBar), ...(sub1 && sub1), ...(sub2 && sub2)]
	return joined.map((item) => {
		return { menuId: item.id, position: item.position }
	})
}
export const createVideoUrl = (url: string) => {
	if (!url) {
		return
	}
	if (url.includes('https://www.youtube.com')) {
		if (!url.includes('/embed/')) {
			let video_id = url.split('v=')[1].split('&')[0];
			return "https://www.youtube.com/embed/" + video_id;
		} else {
			return url;
		}
	} else {
		if (url.includes('https://vimeo.com/')) {
			let video_id = url.split('https://vimeo.com/')[1].split('&')[0];
			return "https://player.vimeo.com/video/" + video_id;
		} else {
			if (url.includes('https://player.vimeo.com/')) {
				return url;
			} else {
				return false;
			}
		}
	}
}


export const setDateAndTimeStart = (defaultValues: any) => {
	if (defaultValues) {
		return defaultValues
	}
	let newDate = new Date()
	newDate.setHours(24, 1)
	return newDate
}
export const setDateAndTimeEnd = (defaultValues: any) => {
	if (defaultValues) {
		return defaultValues
	}
	let newDate = new Date()
	newDate.setHours(23, 59)
	return newDate
}


export const formatNumber = (value: number) => {
	if (typeof value !== 'number') {
		return '';
	}

	return new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(value);
}

export const formatNumberForCSV = (value: number) => {
	return formatNumber(value).replace(/,/g, ';')
}

export const generateEmptyArray = (size: number) => {
	function* generateElement(size: number) {
		for (let i = 0; i <= size; i++) {
			yield i
		}
	}
	//@ts-ignore
	return new Array(...generateElement(size))
}

export const compare = (a: string | number, b: string | number): 0 | 1 | -1 => {
	return a === b ? 0 : a > b ? 1 : -1
}
export const getCountryFromStores = (data: any[]) => {
	if (!data.length) {
		return "en"
	}
	if (!data?.[0]?.country) {
		return "en"
	}
	return data?.[0]?.country?.toLowerCase()
}
export const sortingInterNationalAlphabetical = (data: any[], property: string, country: string) => {
	if (!data.length) {
		return []
	}
	const deepClone: any[] = structuredClone(data)
	return deepClone.sort((a, b) => a?.[property]?.localeCompare(b?.[property], country, { ignorePunctuation: true }))
}