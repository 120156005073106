import { getApiPrefix } from "shared/helpers/genericHelper"
import { BaseApi } from "./base"
import { sessionStorageSync } from "shared/helpers/storageHelper"
import { BASE_URL } from "shared/constants/generic"

export interface IFetchProductsEcommerce {
  brandId: number,
  seasonId: number,
  store: boolean
  filterBy?: string
  selectedFilter?: string,
  language: string
}

export interface IFetchProductsFilter {
  filterBy: string,
  seasonId: number,
  language: string
}
export enum ProductsFilter {
  Line = 'lines',
  Category = 'categories',
  Model = "models"
}
export const fetchProductsEcommerce = ({ brandId, seasonId, store, language }: IFetchProductsEcommerce) => {
  const http = new BaseApi("/productservice/api")
  http.setAdditionalHeaders({ "Language": language })
  return http.get(`/products/dashboard?cacheBuster=${+new Date()}&brandId=${brandId}&seasonId=${seasonId}&store=${store}`)
}
export interface IFetchProductsEcommerceByBarcode {
  barcode: string
}
export const fetchProductsEcommerceByBarcode = ({ barcode }: IFetchProductsEcommerceByBarcode) => {
  const http = new BaseApi("/productservice/api/products");
  http.setAdditionalHeaders({ "Language": getApiPrefix() })
  return http.get(`/get-parent-id-of-unique-barcode?cacheBuster=${+new Date()}&barcode=${barcode}`)
}

export const fetchProductsFilter = ({ filterBy, seasonId, language }: IFetchProductsFilter) => {
  const http = new BaseApi("/productservice/api/attributes");
  http.setAdditionalHeaders({ "Language": language })
  return http.get(`/${filterBy}?cacheBuster=${+new Date()}&seasonId=${seasonId}`)
}

export const fetchFilteredProductsBy = ({ brandId, seasonId, store, filterBy, selectedFilter }: IFetchProductsEcommerce) => {
  const http = new BaseApi("/productservice/api")
  http.setAdditionalHeaders({ "Language": getApiPrefix() })
  //@ts-ignore
  return http.get(`/products/dashboard?cacheBuster=${+new Date()}&brandId=${brandId}&${ProductsFilter[selectedFilter]}=${filterBy?.replace(' ', '+')}&seasonId=${seasonId}&store=${store}`)
}
export interface IFetchProductsOutOfStocks {
  brandId: number
}

export const fetchProductsOutOfStocks = ({ brandId }: IFetchProductsOutOfStocks) => {
  const http = new BaseApi("/productservice/api")
  http.setAdditionalHeaders({ "Language": getApiPrefix() })
  return http.get(`/products/out-of-stock-product-dashboard?cacheBuster=${+new Date()}&brandId=${brandId}`)
}

export interface IFetchProductsUnknown {
  brandId: number
}

export const fetchProductsUnknown = ({ brandId }: IFetchProductsUnknown) => {
  const http = new BaseApi("/productservice/api")
  http.setAdditionalHeaders({ "Language": getApiPrefix() })
  return http.get(`/products/unknown-product-dashboard?cacheBuster=${+new Date()}&brandId=${brandId}`)
}
export const fetchProductsImport = () => {
  const http = new BaseApi("/productservice/api")
  http.setAdditionalHeaders({ "Language": getApiPrefix() })
  return http.get(`/products/import-product-dashboard?cacheBuster=${+new Date()}`)
}
export const fetchProductsImportbyFileImportId = ({ productFileImportId }: { productFileImportId: number }) => {
  const http = new BaseApi("/productservice/api")
  http.setAdditionalHeaders({ "Language": getApiPrefix() })
  return http.get(`/products/import-product-details?cacheBuster=${+new Date()}&productFileImportId=${productFileImportId}`)
}

export const fetchImportProducts = ({ body }: any) => {
  const http = new BaseApi("/productservice/api")
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${sessionStorageSync.privateToken}`)
  myHeaders.append('Accept', 'application/json, text/plain, */*');
  myHeaders.append("Language", getApiPrefix());
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
  };
  //@ts-ignore
  return fetch(`${BASE_URL}/productservice/api/products/import-products?cacheBuster=1716961506363`, requestOptions,)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.error(error));
}
export interface IFetchproductDetailsById {
  id: number | string,
  country: string
  currency: string
  language: string
  menuId?: string | null
}
export const fetchProductDetailsById = ({ id, country, currency, language, menuId }: IFetchproductDetailsById) => {
  const http = new BaseApi("/productservice/api/products")
  if (menuId) {
    const http = new BaseApi("/productservice/api/menus")
    return http.get(`/${menuId}/products/${id}?cacheBuster=${+new Date()}&country=${country}&currency=${currency}&language=${language}`)
  }
  return http.get(`/${id}?cacheBuster=${+new Date()}&country=${country}&currency=${currency}&language=${language}`)
}

export const fetchLanguages = () => {
  const http = new BaseApi("/productservice/api")
  return http.get(`/languages`)
}

export const fetchUpdateProductAttribute = ({ body }: Record<string, any>) => {
  const http = new BaseApi("/productservice/api/products")
  http.setAdditionalHeaders({ "Language": getApiPrefix() })
  return http.put(`/update-product-attribute?cacheBuster=1717571565121`, body)
}
export const fetchProductUpdateInSale = ({ inSale, productId }: { inSale: boolean, productId: number }) => {
  const http = new BaseApi("/productservice/api/products")
  return http.put(`/${productId}/update-in-sale?cacheBuster=1717489642085&inSale=${inSale}`, {})
}
export const fetchProductUpdateStoreSale = ({ storeSale, productId }: { storeSale: boolean, productId: number }) => {
  const http = new BaseApi("/productservice/api/products")
  return http.put(`/${productId}/update-store-sale?cacheBuster=1717489642085&storeSale=${storeSale}`, {})
}

export const fetchUpdateProductDescription = (body: any) => {
  const http = new BaseApi("/productservice/api/products")
  http.setAdditionalHeaders({ "LANGUAGE": getApiPrefix() })
  return http.put("/update-product-description", body)
}
export const fetchProductsCompositeUpdate = ({ productId, collectionId }: { productId: number, collectionId: number }) => {
  const http = new BaseApi("/productservice/api/products")
  http.setAdditionalHeaders({ "LANGUAGE": getApiPrefix() })
  return http.put(`/${productId}/composite/${collectionId}`, {})
}
export const fetchProductsCompositeDelete = ({ productId, collectionId }: { productId: number, collectionId: number }) => {
  const http = new BaseApi("/productservice/api/products")
  http.setAdditionalHeaders({ "LANGUAGE": getApiPrefix() })
  return http.delete(`/${productId}/composite/${collectionId}`)
}
export const fetchProductsRecommendUpdate = ({ productId, collectionId }: { productId: number, collectionId: number }) => {
  const http = new BaseApi("/productservice/api/products")
  http.setAdditionalHeaders({ "LANGUAGE": getApiPrefix() })
  return http.put(`/${productId}/recommendedproduct/${collectionId}`, {})
}
export const fetchProductsRecommendDelete = ({ productId, collectionId }: { productId: number, collectionId: number }) => {
  const http = new BaseApi("/productservice/api/products")
  http.setAdditionalHeaders({ "LANGUAGE": getApiPrefix() })
  return http.delete(`/${productId}/recommendedproduct/${collectionId}`)
}
export const fetchProductVisualUpload = ({ productId, body }: { productId: number, body: any }) => {
  const myHeaders = new Headers()
  myHeaders.append("Authorization", `Bearer ${sessionStorageSync.privateToken}`)
  myHeaders.append("Language", getApiPrefix());
  const formData = new FormData()
  formData.append("file", body)
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };
  return fetch(`${BASE_URL}/productservice/api/products/${productId}/product-visual/upload?`, requestOptions).then(response => response.json())
}
export const fetchRemoveProductVisualByVisualCode = ({ productId, visualCode }: { productId: number, visualCode: string }) => {
  const http = new BaseApi("/productservice/api/products")
  http.setAdditionalHeaders({ "LANGUAGE": getApiPrefix() })
  return http.delete(`/${productId}/remove-product-visual-by-visual-code?&visualCode=${visualCode}`)
}


// https://testnet.waveum.io/productservice/api/products/deactivate-products?cacheBuster=1726656114318

export const fetchDeactivateProducts = async ({ web, file }: { web: boolean, file: File }) => {
  const formData = new FormData()
  formData.append("web", `${web}`)
  formData.append("file", file)
  const response = await fetch(`${BASE_URL}/productservice/api/products/deactivate-products?`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${sessionStorageSync.privateToken}`,
      "Language": `${getApiPrefix()}`,
    },
    body: formData
  })
  return await response.json()
}


interface IPDetailsMenu {
  menuId: string | number,
  productId: string | number
}
interface IVisibleUpdate extends IPDetailsMenu {
  visible?: boolean,
  onDisplay?: boolean
  onSpecial?: boolean
}

export const fetchProductsMenuUpdateVisible = ({ menuId, productId, visible, onDisplay, onSpecial }: IVisibleUpdate) => {
  const http = new BaseApi('/productservice/api/menus')
  if (visible !== undefined) {
    return http.put(`/${menuId}/products/${productId}/update-visible?&visible=${visible}`, {})
  }
  if (onDisplay !== undefined) {
    return http.put(`/${menuId}/products/${productId}/update-on-display?&onDisplay=${onDisplay}`, {})
  }
  if (onSpecial !== undefined) {
    return http.put(`/${menuId}/products/${productId}/update-on-special?&onSpecial=${onSpecial}`, {})
  }
}
export const fetchUpdateBarCodeInSale = ({ barcodeProductId, inSale, language }: { barcodeProductId: number, inSale: boolean, language: string }) => {
  const http = new BaseApi('/productservice/api/products')
  http.setAdditionalHeaders({ "LANGUAGE": language })
  return http.put(`/update-barcode-in-sale?&barcodeProductId=${barcodeProductId}&inSale=${inSale}`, {})
}