import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'shared/ui/Button'
import { InputWithIcon } from 'shared/ui/InputWithIcon'
import "./index.scss"
import { FormEventHandler, ReactNode } from 'react'
import { handleChange } from '../model'
import { VALIDATION_CASES } from 'shared/helpers/formsHelper'

interface IPassform {
    icon: ReactNode,
    placeholder: string
    className: string
    name: string
    onChange: FormEventHandler<HTMLInputElement>
}

const formData: Array<IPassform> = [
    {
        icon: <FontAwesomeIcon icon={faLock} />,
        placeholder: 'Current Password',
        className: 'form-inp',
        onChange: handleChange,
        name: "currentPassword"
    },
    {
        icon: <FontAwesomeIcon icon={faLock} />,
        placeholder: 'New Password',
        className: 'form-inp',
        onChange: handleChange,
        name: "newPassword"
    }, {
        icon: <FontAwesomeIcon icon={faLock} />,
        placeholder: 'Re-Enter New Password',
        className: 'form-inp',
        onChange: handleChange,
        name: "newPassCheck"
    }
]
interface IPasswordForm {
    handleSubmit: () => void
    error: null | Object
    backendError?: string
}
const PasswordForm = ({ handleSubmit, error, backendError }: IPasswordForm) => {

    return (
        <div className='form-conteiner'>
            {formData.map((input: IPassform) => {

                return (
                    <div>
                        {input.name === "currentPassword" && backendError && <div className='error-message'>{backendError}</div>}
                        {/* @ts-ignore */}
                        {error && <div className='error-message'>{error[VALIDATION_CASES.EMPTY]?.msg || error[VALIDATION_CASES.NOMATCH]?.msg || error[VALIDATION_CASES.REGEX]?.msg}</div>}
                        <InputWithIcon {...input} key={input.placeholder} />
                    </div>
                )
            })}
            <div className='button-wrraper'>
                <Button className='save-button' onClick={handleSubmit}>Change Password</Button>
            </div>
        </div>
    )
}
export default PasswordForm