import { Store, createStore } from "effector";
import { getUserFx } from "features/myProfileModal/effects";

export interface IUser {
    firstName: string,
    lastName: string,
    email: string,
    image: string,
    company: string
    userImage: string,
}

export const $user: Store<IUser | Object> = createStore({});
$user.on(getUserFx.doneData, (_, user: IUser) => user);