import { IPasswordForm } from "entities/passwordForm/model";

export enum VALIDATION_CASES {
	NOMATCH = "NOMATCH",
	EMPTY = "EMPTY",
	REGEX = "REGEX",
	SUCCESS = "SUCCESS"
}
export const checkPasswordProp = ({ currentPassword = '', newPassCheck = '', newPassword = '' }: IPasswordForm) => {
	const regularExpression = /\d/;
	const charInPass = 8

	if (!(currentPassword && newPassCheck && newPassword)) {
		return VALIDATION_CASES.EMPTY
	} else if (!regularExpression.test(newPassword) || newPassword.length < charInPass) {
		return VALIDATION_CASES.REGEX
	} else if (newPassCheck !== newPassword) {
		return VALIDATION_CASES.NOMATCH
	}
	return VALIDATION_CASES.SUCCESS
}

export const getPasswordError = ({ currentPassword = '', newPassCheck = '', newPassword = '' }: IPasswordForm) => {
	let error: any = {}
	switch (checkPasswordProp({ currentPassword, newPassCheck, newPassword })) {
		case VALIDATION_CASES.EMPTY: {
			error[VALIDATION_CASES.EMPTY] = { msg: "Fields is Empty" }
			break
		}
		case VALIDATION_CASES.NOMATCH: {
			error[VALIDATION_CASES.NOMATCH] = { msg: "Passwords is not matched" }
			break
		}
		case VALIDATION_CASES.REGEX: {
			error[VALIDATION_CASES.REGEX] = { msg: "Password must be more than 6 char and must have numbers and symbols" }
			break
		}
		default: {
			error = null
		}

	}

	return error
}