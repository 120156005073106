import { NAVBAR_HARDCODE, ROLE_BASED_ROUTING } from "shared/constants/routes"
import { MARKETPLACE_BASE_URL, PARTNER_BASE_URL, STORE_BASE_URL, WHOLESALE_BASE_URL } from "utils/constants/navbarPaths";

export const getMenusByUserRole = (user: any) => {
  if (!user || !user?.authorities?.length) {
    return []
  }
  const routes = user.authorities.reduce((acc: any[], role: string) => {
    if (role in ROLE_BASED_ROUTING) {
      //@ts-ignore
      acc.push(...ROLE_BASED_ROUTING[role])
    }
    return acc
  }, [])
  //@ts-ignore
  return [...new Set([...routes])]
}
export const getMenuByIds = (user: any) => {
  const navbar = []
  const ids = getMenusByUserRole(user)
  for (let key of ids) {
    for (let item of NAVBAR_HARDCODE) {
      if (item.id === key) {
        navbar.push(item)
      }
    }
  }
  return navbar.sort((a, b) => a.id - b.id)
}

export const saveTokenInCookies = async (brandApp: number, token: string) => {
	const url = getTerminalUrlByBrandApp(brandApp);
	const loginPath = '/fr/login';

	url && setSubdomainCookie(token, url);
	window.open(url + loginPath, "_self");
}

export const setSubdomainCookie = (token: string, url: string) => {
	const expiredDate = new Date(new Date().getTime() + 1000 * 1200).getTime();
	const [domain] = process.env.NODE_ENV === "development"
		? ["localhost"]
		: (url.match(/\.[A-z]*\.[A-z]*$/g) || [""]);

	document.cookie = `privateToken=${token};expires=${expiredDate};domain=${domain};path=/`;
}

export const getTerminalUrlByBrandApp = (brandApp: number) => {
	switch (brandApp) {
		case 5:
			return STORE_BASE_URL;
		case 9:
			return WHOLESALE_BASE_URL;
		case 12:
			return PARTNER_BASE_URL;
		case 22:
			return MARKETPLACE_BASE_URL;
		default:
			return null;
	}
}