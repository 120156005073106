import { createEffect } from "effector";
import { IFetchProductsEcommerce, IFetchProductsEcommerceByBarcode, fetchDeactivateProducts, fetchProductsEcommerce, fetchProductsEcommerceByBarcode } from "shared/api/productsApi";
import { ISeasons, fetchSeasons } from "shared/api/seasonsApi";

export const getProductsEcommerceFx = createEffect(async (body: IFetchProductsEcommerce) => {
  try {
    const products = await fetchProductsEcommerce(body);
    return products
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
});

export const getProductsBybarcodeFx = createEffect(async ({ barcode }: IFetchProductsEcommerceByBarcode) => {
  try {
    const products = await fetchProductsEcommerceByBarcode({ barcode })
    return products
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
})

export const getSeasonsFx = createEffect(async (body: ISeasons) => {
  try {
    const seasons = await fetchSeasons(body)
    return seasons
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
})


export const getDeactivateProductsFx = createEffect(async ({ file, web }: { file: File, web: boolean }) => {
  try {
    return await fetchDeactivateProducts({ file, web })
  } catch (err: any) {
    if (err instanceof Error) {
      throw new Error(err.message)
    }
  }
})
