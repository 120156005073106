import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { HARDCODE_SUBROUTE, NAVBAR_HARDCODE, ROLE_BASED_ROUTING } from 'shared/constants/routes'
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useClickOutSide } from 'shared/hooks/useClickOutSide'
import { getLang, getLangPrefix, parseJwtData, urlToLabel } from 'shared/helpers/genericHelper'
import "./header.scss"
import { getUserFx } from 'features/myProfileModal/effects'
import { $user } from '../../entities/user/model'
import { useUnit } from 'effector-react'
import { changeGlobalBrandFx, getBrandsFx } from 'features/brands/effects/brands'
import { $brandStore, $brandsState } from 'entities/brand/model/brand'
import { BASE_URL } from 'shared/constants/generic'
import { getSeasonsFx } from 'features/products/effects/ecommerce'
import HeaderBreadCrumb from './Breadcrumb'
import SubMenu from './SubMenu'
import { Link } from 'react-router-dom'
import { localStorageSync } from 'shared/helpers/storageHelper'
import i18next from 'i18next'
import { getMenuByIds } from './helpers'
import useNavigateByRole from './SubMenu/useNavigateByRole'
import { $seasons } from 'entities/seasons/model'

interface IHeader {
    children: ReactNode
}
const Header = ({ children }: IHeader) => {
    const [state, setState] = useState<any>([])
    const [brandsLabel, setBrandsLabel] = useState('')
    const { brandsStore }: any = useUnit($brandStore)
    const { selectedBrand } = useUnit($brandsState);
    const { success }: any = useUnit($user)
    const user: any = useUnit($user)
    const [menuItemState, setMenuItemState] = useState<any>({ isActive: false })
    const urlPath = useLocation()
    const popupRef = useRef(null)
    const navigate = useNavigate()
    useEffect(() => {
        getSeasonsFx({ brandId: selectedBrand })
    }, [selectedBrand])

    useEffect(() => {
        getBrandsFx()
        if (brandsStore?.length) {
            HARDCODE_SUBROUTE.DESCAMPS = structuredClone(brandsStore)
        }
    }, [brandsStore?.length])

    useEffect(() => {
        getUserFx()
    }, [success])

    const handleMenuClick = async (label: string) => {
        setMenuItemState({ label: label, isActive: !menuItemState.isActive })
        setState(HARDCODE_SUBROUTE[label])
    }
    const closeMenuPopup = () => {
        setMenuItemState({ ...menuItemState, isActive: false })
    }
    const handleChangeBrand = (brand: any) => {
        setBrandsLabel(brand.name)
        changeGlobalBrandFx(brand)
        getSeasonsFx({ brandId: brand.id })
    }
    useClickOutSide(popupRef, closeMenuPopup)
    useNavigateByRole()
    const defaultRoutes = useMemo(() => {
        return getMenuByIds(user)
    }, [user?.authorities?.length])
    const handleAiNavigate = (link: "AI ASSISTANT") => {
        navigate(`${getLangPrefix()}/ai_assistant`, { replace: true })
    }
    return (
        <div className='min-h-[100vh]'>
            <header className='menu_header'>
                <nav className='navigation_conteiner'>
                    {defaultRoutes.map(({ label }) => {
                        return (<div key={label} className={`navbar_item ${urlToLabel(urlPath.pathname).includes(label) ? 'active_menu' : ''}`}
                            onClick={() => {
                                if (label === "AI ASSISTANT") {

                                    handleAiNavigate(label)
                                } else {
                                    handleMenuClick(label)
                                }

                            }}
                        >
                            {/* {label === "AI ASSISTANT" && <NavLink to={{ pathname: `${getLangPrefix()}/ai-assistant` }} >AI ASSISTANT</NavLink>} */}
                            {label === "DESCAMPS" && brandsLabel ? brandsLabel : label}
                            <div className={`popup_conteiner ${label === "DESCAMPS" ? 'brands_popup' : ''}`}>

                                {label === menuItemState.label && menuItemState.isActive && state?.length ? <div ref={popupRef} className='rounded-2xl sub_container'>

                                    {
                                        state.map((item: any) => {
                                            return <div key={item.route} className='navbar_popup'>
                                                {item.logoUrl && <div onClick={() => handleChangeBrand(item)}>
                                                    <p >{item.name}</p>
                                                    <img src={`${BASE_URL}${item.logoUrl}`} className='brand_image' />
                                                </div>}
                                                {/* @ts-ignore */}
                                                <NavLink to={{ pathname: `${getLangPrefix()}${item.route}` }} className={({ isActive }) => `popup_link ${isActive ? "active_menu" : ""}`}>
                                                    {item.label}
                                                </NavLink>
                                            </div>
                                        })
                                    }
                                </div> : []}
                            </div>
                        </div>)
                    })}
                </nav>
            </header>
            <SubMenu />
            {children}
        </div>
    )
}
export default Header