import { createEvent, createStore } from 'effector';
import { getProductsBybarcodeFx, getProductsEcommerceFx } from 'features/products/effects/ecommerce';
import { getFilteredProductsFx } from 'features/products/effects/filters';

export const $productsEcommerce = createStore<Record<string, any>>({});

$productsEcommerce.on(getProductsEcommerceFx.doneData, (state, result) => {
    return {
        ...state,
        products: result,
    };
});

$productsEcommerce.on(getFilteredProductsFx.doneData, (state, result) => {
    return {
        ...state,
        products: result,
    };
});

export const $productsBybarcodeStore = createStore({})
export const resetProductId = createEvent()
$productsBybarcodeStore.on(getProductsBybarcodeFx.doneData, (state, payload) => {
    return {
        productId: payload
    }
}).reset(resetProductId)