
const Footer = () => {
  return (
    <div className='w-full h-10 bg-[#153b5e] flex justify-center items-center'>
      <p className="text-white">
        © 2024 Waveum Inc. | Cliquez ici pour contacter le support technique
      </p>
    </div>
  )
}

export default Footer