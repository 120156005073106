import PasswordForm from "entities/passwordForm/ui"
import "./index.scss"
import { useUnit } from "effector-react"
import { $passwordStore } from "entities/passwordForm/model"
import { useState } from "react"
import { getPasswordError } from "shared/helpers/formsHelper"
import { sendPasswordFX } from "../effects"

interface IChangePassword {
    closeModal: () => void
}
const ChangePassowrdModal = ({ closeModal }: IChangePassword) => {
    const { currentPassword, newPassCheck, newPassword } = useUnit($passwordStore)
    const [fieldError, setFieldError] = useState({})
    const [backEndError, setBackendError] = useState('')
    const handleSubmit = () => {
        const error = getPasswordError({ currentPassword, newPassCheck, newPassword })
        if (!error) {
            setFieldError(error)
            sendPasswordFX({ currentPassword, newPassword })
        } else {
            setFieldError(error)
        }
        sendPasswordFX.fail.watch(({ params, error }) => {
            //@ts-ignore
            setBackendError(error)
        });
        sendPasswordFX.done.watch(({ params, result }) => {

            if (result && result.status === "SUCCESS") {
                closeModal()
            }
        })
    }
    return (
        <div className='changePassword-conteiner'>
            <div className='header'>CHANGE PASSWORD</div>
            <PasswordForm handleSubmit={handleSubmit} error={fieldError} backendError={backEndError.toString()} />
        </div>
    )
}

export default ChangePassowrdModal


