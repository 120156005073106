import { createEffect } from "effector";
import { fetchBrands } from "shared/api/brandsApi";

export const getBrandsFx = createEffect(async () => {
  try {
    const data = await fetchBrands()
    return data
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
})

export const changeGlobalBrandFx = createEffect((params: any) => {
  return params
})