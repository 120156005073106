import { useUnit } from 'effector-react'
import { $user } from 'entities/user/model'
import i18next from 'i18next'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { getLang } from 'shared/helpers/genericHelper'

const NAVIAGTION_URL_BY_ROLE = {
    ROLE_ADMIN: "manageusers",
    ROLE_LOGISTIC: "logistic-transfer",
    ROLE_WHOLESALE: "wholesale-orders",
    ROLE_WEB: "web-orders",
    ROLE_STORE: "store-sales",
    ROLE_CONTENT_MANAGER: "content-menubar",
    ROLE_CUSTOMER_SERVICE: "customer-services-tickets"
}
const enum ROLE {
    ADMIN = " ROLE_ADMIN",
    LOGISTIC = "ROLE_LOGISTIC",
    WHOLESALE = "ROLE_WHOLESALE",
    WEB = "ROLE_WEB",
    STORE = "ROLE_STORE",
    CONTENT_MANAGER = "ROLE_CONTENT_MANAGER",
    CUSTOMER_SERVICE = "ROLE_CUSTOMER_SERVICE"
}

const getUrlByRoles = (roles: string[]) => {
    if (roles.includes(ROLE.STORE)) {
        return NAVIAGTION_URL_BY_ROLE.ROLE_STORE
    } else if (roles.includes(ROLE.WHOLESALE)) {
        return NAVIAGTION_URL_BY_ROLE.ROLE_WHOLESALE
    } else if (roles.includes(ROLE.WEB)) {
        return NAVIAGTION_URL_BY_ROLE.ROLE_WEB
    } else if (roles.includes(ROLE.ADMIN)) {
        return NAVIAGTION_URL_BY_ROLE.ROLE_ADMIN
    } else if (roles.includes(ROLE.LOGISTIC)) {
        return NAVIAGTION_URL_BY_ROLE.ROLE_LOGISTIC
    } else if (roles.includes(ROLE.CONTENT_MANAGER)) {
        return NAVIAGTION_URL_BY_ROLE.ROLE_CONTENT_MANAGER
    } else if (roles.includes(ROLE.CUSTOMER_SERVICE)) {
        return NAVIAGTION_URL_BY_ROLE.ROLE_CUSTOMER_SERVICE
    }
}

const useNavigateByRole = () => {
    const user: any = useUnit($user)
    const navigate = useNavigate()
    useEffect(() => {
        if (localStorage.getItem('language') && !sessionStorage.getItem("configLang")) {
            if (user && user?.authorities) {
                navigate(`/${getLang()}/${getUrlByRoles(user.authorities)}`, { replace: true })
                sessionStorage.setItem("configLang", JSON.stringify(true))
                i18next.changeLanguage(getLang())
            }
        }
    }, [localStorage.getItem("language"), user])
}
export default useNavigateByRole