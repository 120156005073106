import { title } from "process"
import { OPENAI } from "./generic"
export const menuHardcodes = [{
  menuId: 1,
  key: "login",
  title: "Waveum",
  canonicalUrl: "/login"
}, {
  menuId: 2,
  key: "home",
  title: "Waveum",
  canonicalUrl: "/web-orders"
}, {
  menuId: 3,
  key: "webSales",
  title: "Waveum",
  canonicalUrl: "/web-sales"
}, {
  menuId: 4,
  key: "storeSales",
  title: "Waveum",
  canonicalUrl: "/store-sales"
}, {
  menuId: 5,
  key: "storeProducts",
  title: "Waveum",
  canonicalUrl: "/store-products"
}, {
  menuId: 6,
  key: "productAnalytics",
  title: "Waveum",
  canonicalUrl: "/products-analytics"
}, {
  menuId: 7,
  key: "logisticSellCoverage",
  title: "Waveum",
  canonicalUrl: "/logistic-sell-coverage"
}, {
  menuId: 8,
  key: "storeTickets",
  title: "Waveum",
  canonicalUrl: "/store-tickets"
}, {
  menuId: 9,
  key: "productsEcommerce",
  title: "Waveum",
  canonicalUrl: "/products-ecommerce"
}, {
  menuId: 10,
  key: "storeDrawer",
  title: "Waveum",
  canonicalUrl: "/store-drawer"
}, {
  menuId: 11,
  key: "storePrepayment",
  title: "Waveum",
  canonicalUrl: "/store-prepayment"
}, {
  menuId: 12,
  key: "promotionsStores",
  title: "Waveum",
  canonicalUrl: "/promotions-stores"
}, {
  menuId: 13,
  key: "promotionsStoreClusters",
  title: "Waveum",
  canonicalUrl: "/promotions-storeclusters"
}, {
  menuId: 14,
  key: "promotionsPromotions",
  title: "Waveum",
  canonicalUrl: "/promotions-promotions"
}, {
  menuId: 15,
  key: "promotionsProductclusters",
  title: "Waveum",
  canonicalUrl: "/promotions-productclusters"
}, {
  menuId: 16,
  key: "promotionsRules",
  title: "Waveum",
  canonicalUrl: "/promotions-rules"
}, {
  menuId: 17,
  key: "promotionsCoupons",
  title: "Waveum",
  canonicalUrl: "/promotions-coupons"
}, {
  menuId: 18,
  key: "productsStore",
  title: "Waveum",
  canonicalUrl: "/products-store"
}, {
  menuId: 19,
  key: "productsOutOfStocks",
  title: "Waveum",
  canonicalUrl: "/products-outofstocks"
}, {
  menuId: 20,
  key: "productsUnknown",
  title: "Waveum",
  canonicalUrl: "/products-unknown"
}, {
  menuId: 21,
  key: "productsImport",
  title: "Waveum",
  canonicalUrl: "/products-import"
}, {
  menuId: 22,
  key: "storeVouchers",
  title: "Waveum",
  canonicalUrl: "/store-vouchers"
}, {
  menuId: 23,
  key: "storeGiftCards",
  title: "Waveum",
  canonicalUrl: "/store-gift-cards"
}, {
  menuId: 24,
  key: "contentBrands",
  title: "Waveum",
  canonicalUrl: "/content-brands"
}, {
  menuId: 25,
  key: "productsOptions",
  title: "Waveum",
  canonicalUrl: "/products-options"
}, {
  menuId: 26,
  key: "productsSurveys",
  title: "Waveum",
  canonicalUrl: "/products-surveys"
}, {
  menuId: 30,
  key: "contentCatalogs",
  title: "Waveum",
  canonicalUrl: "/content-catalogs"
}, {
  menuId: 31,
  key: "contentStaticPages",
  title: "Waveum",
  canonicalUrl: "/content-staticpages"
}, {
  menuId: 32,
  key: "contentProductPages",
  title: "Waveum",
  canonicalUrl: "/content-productpages"
}, {
  menuId: 33,
  key: "contentMenuBar",
  title: "Waveum",
  canonicalUrl: "/content-menubar"
}, {
  menuId: 34,
  key: "contentGrids",
  title: "Waveum",
  canonicalUrl: "/content-grids"
}, {
  menuId: 35,
  key: "contentGridsDetails",
  title: "Waveum",
  canonicalUrl: "/content-grids/*"
}, {
  menuId: 36,
  key: "logisticInventoryrequest",
  title: "Waveum",
  canonicalUrl: "/logistic-inventoryrequest"
}, {
  menuId: 37,
  key: "logisticInventory",
  title: "Waveum",
  canonicalUrl: "/logistic-inventory"
}, {
  menuId: 38,
  key: "logisticDelivery",
  title: "Waveum",
  canonicalUrl: "/logistic-delivery"
}, {
  menuId: 39,
  key: "logisticTransfer",
  title: "Waveum",
  canonicalUrl: "/logistic-transfer"
}, {
  menuId: 40,
  key: "logisticStock",
  title: "Waveum",
  canonicalUrl: "/logistic-stock"
}, {
  menuId: 41,
  key: "logisticTransit",
  title: "Waveum",
  canonicalUrl: "/logistic-transit"
}, {
  menuId: 42,
  key: "clientsStore",
  title: "Waveum",
  canonicalUrl: "/clients-store"
}, {
  menuId: 43,
  key: "clientsWeb",
  title: "Waveum",
  canonicalUrl: "/clients-web"
}, {
  menuId: 44,
  key: "clientsEmployees",
  title: "Waveum",
  canonicalUrl: "/clients-employees"
}, {
  menuId: 45,
  key: "manageUsers",
  title: "Waveum",
  canonicalUrl: "/manageusers"
}, {
  menuId: 46,
  key: "customerServiceTickets",
  title: "Waveum",
  canonicalUrl: "/customer-services-tickets"
}, {
  menuId: 47,
  key: "productsEcommerceDetails",
  title: "Waveum",
  canonicalUrl: "/products-ecommerce/*"
}, {
  menuId: 48,
  key: "contentProductPagesDetails",
  title: "Waveum",
  canonicalUrl: "/content-productpages/*"
}, {
  menuId: 49,
  key: "contentProductPagesDetailsById",
  title: "Waveum",
  canonicalUrl: "/content-productpages-details/*"
}, {
  menuId: 50,
  key: "contentMenuBarDetails",
  title: "Waveum",
  canonicalUrl: "/content-menubar/*"
},
{
  menuId: 51,
  key: "promotionsProductClustersDetails",
  title: "Waveum",
  canonicalUrl: "/promotions-productclusters/*"
},
{
  menuId: 52,
  key: "promotionsPromotionsDetails",
  title: "Waveum",
  canonicalUrl: "/promotions-promotions/*"
},
{
  manuId: 53,
  key: "productsStoreDetails",
  title: "Waveum",
  canonicalUrl: "/products-store/*"
},
{
  manuId: 54,
  key: "productsOutofstocksDetails",
  title: "Waveum",
  canonicalUrl: "/products-outofstocks/*"
},
{
  manuId: 55,
  key: "wholesaleOrders",
  title: "Waveum",
  canonicalUrl: "/wholesale-orders"
},
{
  manuId: 56,
  key: "wholesaleClients",
  title: "Waveum",
  canonicalUrl: "/wholesale-clients"
},
{
  manuId: 57,
  key: "storeInvoices",
  title: "Waveum",
  canonicalUrl: "/store-invoices"
},
{
  manuId: 58,
  key: "aiAssistant",
  title: "Waveum",
  canonicalUrl: "/ai_assistant"
}
]

export interface INavbar {
  id: number
  label: string
  isActive: boolean
  subRoute?: Array<INavbar>
}

export const NAVBAR_HARDCODE: Array<INavbar> = [

  { id: 1, label: "WEB", isActive: false, },
  { id: 2, label: "STORE", isActive: false, },
  { id: 3, label: "WHOLESALE", isActive: false, },
  { id: 4, label: "PRODUCTS", isActive: false, },
  { id: 5, label: "PROMOTIONS", isActive: false, },
  { id: 6, label: "CONTENT", isActive: false, },
  { id: 7, label: "CLIENTS", isActive: false, },
  { id: 8, label: "CUSTOMER SERVICE", isActive: false, },
  { id: 9, label: "LOGISTIC", isActive: false, },
  { id: 10, label: "DESCAMPS", isActive: false, },
  { id: 11, label: "ADMIN", isActive: false, },

]
//@ts-ignore
if (OPENAI == 1) {
  NAVBAR_HARDCODE.unshift({ id: 0, label: "AI ASSISTANT", isActive: false },)
}
export const ROLE_BASED_ROUTING = {
  ROLE_ADMIN: [10, 11, 0],
  ROLE_LOGISTIC: [10, 4, 9, 0],
  ROLE_WHOLESALE: [10, 4, 3, 0],
  ROLE_STORE: [10, 4, 2, 7, 0],
  ROLE_WEB: [10, 4, 1, 6, 7, 5, 0],
  ROLE_CONTENT_MANAGER: [10, 4, 6, 0],
  ROLE_CUSTOMER_SERVICE: [10, 4, 8, 1, 0]
}

export const HARDCODE_SUBROUTE: any = {
  WEB: [
    { id: 2, label: 'ORDERS', isActive: false, route: "/web-orders" },
    { id: 3, label: 'SALES', isActive: false, route: "/web-sales" }
  ],
  STORE: [
    { id: 4, label: 'SALES', isActive: false, route: "/store-sales" },
    { id: 5, label: "PRODUCTS", isActive: false, route: "/store-products" },
    { id: 6, label: "TICKETS", isActive: false, route: "/store-tickets" },
    { id: 11, label: "INVOICES ", isActive: false, route: "/store-invoices" },
    { id: 7, label: "DRAWER", isActive: false, route: "/store-drawer" },
    { id: 8, label: "VOUCHERS", isActive: false, route: "/store-vouchers" },
    { id: 9, label: "GIFT CARDS", isActive: false, route: "/store-gift-cards" },
    { id: 10, label: "PREPAYMENT", isActive: false, route: "/store-prepayment" },
  ],
  WHOLESALE: [
    { id: 11, label: 'ORDERS', isActive: false, route: "/wholesale-orders" },
    { id: 12, label: 'CLIENTS', isActive: false, route: "/wholesale-clients" }
  ],
  PRODUCTS: [
    { id: 13, label: 'E-COMMERCE', isActive: false, route: "/products-ecommerce" },
    { id: 14, label: "STORE", isActive: false, route: "/products-store" },
    { id: 15, label: "OUT OF STOCKS", isActive: false, route: "/products-outofstocks" },
    { id: 16, label: "UNKNOWN", isActive: false, route: "/products-unknown" },
    { id: 17, label: "IMPORT", isActive: false, route: "/products-import" },
    { id: 18, label: "ANALYTICS", isActive: false, route: "/products-analytics" },
    { id: 19, label: "OPTIONS", isActive: false, route: "/products-options" },
    { id: 20, label: "SURVEYS", isActive: false, route: "/products-surveys" },
  ],
  PROMOTIONS: [
    { id: 21, label: 'STORES', isActive: false, route: "/promotions-stores" },
    { id: 22, label: "STORE CLUSTERS", isActive: false, route: "/promotions-storeclusters" },
    { id: 23, label: "PROMOTIONS", isActive: false, route: "/promotions-promotions" },
    { id: 24, label: "PRODUCT CLUSTERS", isActive: false, route: "/promotions-productclusters" },
    { id: 25, label: "RULES", isActive: false, route: "/promotions-rules" },
    { id: 26, label: "COUPONS", isActive: false, route: "/promotions-coupons" },
  ],
  CONTENT: [
    { id: 27, label: 'BRANDS', isActive: false, route: "/content-brands" },
    { id: 28, label: "CATALOGS", isActive: false, route: "/content-catalogs" },
    { id: 29, label: "STATIC PAGES", isActive: false, route: "/content-staticpages" },
    { id: 30, label: "MENU BAR", isActive: false, route: "/content-menubar" },
    { id: 31, label: "PRODUCT PAGES", isActive: false, route: "/content-productpages" },
    { id: 32, label: "GRIDS", isActive: false, route: "/content-grids" },
  ],
  CLIENTS: [
    { id: 33, label: 'STORE', isActive: false, route: "/clients-store" },
    { id: 34, label: "WEB", isActive: false, route: "/clients-web" },
    { id: 35, label: "EMPLOYEES", isActive: false, route: "/clients-employees" },
  ],
  "CUSTOMER SERVICE": [{ id: 5, label: "TICKETS", isActive: false, route: "/customer-services-tickets" }],

  LOGISTIC: [
    { id: 36, label: 'INVENTORY REQUEST', isActive: false, route: "/logistic-inventoryrequest" },
    { id: 37, label: "INVENTORY", isActive: false, route: "/logistic-inventory" },
    { id: 38, label: "DELIVERY", isActive: false, route: "/logistic-delivery" },
    { id: 39, label: "TRANSFER", isActive: false, route: "/logistic-transfer" },
    { id: 40, label: "STOCK", isActive: false, route: "/logistic-stock" },
    { id: 41, label: "TRANSIT", isActive: false, route: "/logistic-transit" },
    { id: 42, label: "SELL - THRU & COVERAGE", isActive: false, route: "/logistic-sell-coverage" },
  ],
  DESCAMPS: [],
  ADMIN: [{ id: 43, label: "MANAGE USERS", isActive: false, route: "/manageusers" }]
}