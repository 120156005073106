
import { createEffect, createEvent, createStore, sample } from "effector";
import { fetchUserUpdate } from "shared/api/userApi";
import { readFileAsBase64 } from "shared/helpers/genericHelper";
import { $user } from "entities/user/model";
import { FormEventHandler, MouseEventHandler } from "react";
export interface IUserForm {
    image?: string
    message?: string
    firstName?: string
    lastName?: string
    userId?: string
    email?: string
}
export const formSubmitted: MouseEventHandler<HTMLButtonElement> = createEvent();
const fieldUpdate = createEvent();
const clearForm = createEvent();
const validateFormFx = createEffect((params: IUserForm) => {
    if ("firstName" in params || 'lastName' in params || 'image' in params) {
        return params
    }
    throw new Error("Not changed field")
});
export const sendFormFx = createEffect(async (params: IUserForm) => {
    const userPrevData: any = $user.getState()
    const userData: any = {
        image: '',
        message: '',
        firstName: userPrevData.firstName,
        lastName: userPrevData.lastName,
        userId: userPrevData.email,
        ...params,
    }
    let img = ''
    await readFileAsBase64(userData.image, async (base64String) => {
        img = base64String
        const formData = new FormData()
        formData.append("image", img)
        formData.append("firstName", userData.firstName)
        formData.append("lastName", userData.lastName)
        formData.append("message", '')
        formData.append("userId", userPrevData.email)
        try {
            const user = await fetchUserUpdate(formData)
            return user
        } catch (err: any) {
            throw new Error(err.message)
        }
    });
});

export const $form = createStore({})
//@ts-ignore
$form.on(fieldUpdate, (form, { key, value }) => ({
    ...form,
    [key]: value,
})).reset(clearForm)

sample({
    //@ts-ignore
    clock: formSubmitted,
    source: $form,
    target: validateFormFx,
});
sample({
    clock: validateFormFx.doneData,
    source: $form,
    target: sendFormFx
})

export const handleChange: FormEventHandler<HTMLInputElement> = fieldUpdate.prepend((event: any) => {
    if (event.target.name === "image") {

        return {
            key: event.target.name,
            value: event.target.files[0],
        }
    }
    return {
        key: event.target.name,
        value: event.target.value,
    };
})
$user
    .on(sendFormFx.pending, (state) => ({ ...state, error: null, isPending: true, success: false }))
    .on(sendFormFx.done, (state) => {
        clearForm()
        return {
            ...state,
            error: null,
            isPending: false,
            success: true
        }
    }).on(sendFormFx.fail, (state, { error }) => {
        return {
            ...state,
            error: error.message
        }
    })



