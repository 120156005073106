import { Store, createEvent, createStore } from "effector"
import { FormEventHandler } from "react"

export interface IPasswordForm {
    currentPassword: string
    newPassword: string
    newPassCheck: string
}
export const fillForm = createEvent()
export const submitPassword = createEvent()
export const $passwordStore: Store<IPasswordForm> = createStore({ newPassCheck: '', newPassword: '', currentPassword: '' })
export const clearPassForm = createEvent()

export const handleChange: FormEventHandler<HTMLInputElement> = fillForm.prepend((event: any) => {
    return {
        key: event.target.name,
        value: event.target.value,
    };
})
//@ts-ignore
$passwordStore.on(fillForm, (form, { key, value }) => ({
    ...form,
    [key]: value,
})).reset(clearPassForm)
