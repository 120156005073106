import { Routing } from "pages";
import { withProviders } from "./withProvider";
import { Suspense } from "react";
import LoaderPage from "shared/ui/LoaderPage";
import { HelmetProvider } from 'react-helmet-async';
import { AppStyles } from "./style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TOAST_STYLES } from "shared/constants/toast";

const App = () => {

  return (
    <HelmetProvider>
      <div className={AppStyles}>
        <Suspense fallback={<LoaderPage />}><Routing /></Suspense>
        <ToastContainer position={TOAST_STYLES.POSITION.TOP_RIGHT} />
      </div>
    </HelmetProvider>
  );
}
export default withProviders(App);