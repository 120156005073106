import { RefObject, useEffect, useRef } from 'react'

const useClickOutSide = (ref: RefObject<null | HTMLElement>, handler: (...args: any) => void) => {
  const clearTimOutId = useRef<any>(null)
  const listener = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handler()
    }
    return
  }
  useEffect(() => {
    clearTimOutId.current = setTimeout(() => {
      document.addEventListener("click", listener)
    })

    return () => {
      document.removeEventListener("click", listener)
      clearTimeout(clearTimOutId.current)
    }
    // eslint-disable-next-line
  })
}

export { useClickOutSide } 