import { useUnit } from "effector-react"
import HeaderBreadCrumb from "../Breadcrumb"
import "./index.scss"
import { $user } from "entities/user/model"
import ProfileInfo from "./ProfileInfo"

const SubMenu = () => {
  const user: any = useUnit($user)
  return (
    <div className='sub-menu-container'>
      <HeaderBreadCrumb currency={user.currency} />
      <ProfileInfo user={user} />
    </div>
  )
}

export default SubMenu