import { getApiPrefix, getLangPrefix, urlToBreadCrumbPath } from "shared/helpers/genericHelper"
import "./index.scss"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { BASE_URL, Currency } from "shared/constants/generic"
import { FC } from "react"
import { resetProductId } from "entities/products/model/ecommerce"
interface IHeaderBreadCrumb {
  currency: Currency
}
const HeaderBreadCrumb: FC<IHeaderBreadCrumb> = ({ currency }) => {
  const urlPath = useLocation()
  const navigate = useNavigate()
  const prevPage = urlPath.pathname.split('/').filter(item => item)

  return (
    <div className='home'>
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center cursor-pointer" onClick={() => {
          navigate(`/${getLangPrefix()}`)
        }}>
          <div className="mr-1" >
            <FontAwesomeIcon icon={faHome} color="orange" />
          </div>
          <h2 style={{ color: "orange" }} className="font-semibold text-[16px] tracking-wider">BACK-OFFICE | {currency} </h2>
        </div>
        <div className="breadCrumb font-semibold text-[16px] cursor-pointer tracking-wider text-white ml-3" onClick={
          (e) => {
            if (prevPage.length > 2) {
              resetProductId()
              navigate(-1)
            }

          }}>
          {"> "}{urlToBreadCrumbPath(urlPath.pathname)}
        </div>
      </div>
    </div>
  )
}
export default HeaderBreadCrumb