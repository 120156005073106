import { faArrowsToCircle, faCartFlatbed, faCashRegister, faRightFromBracket, faServer, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BRAND_ID, WAVEUM_LOGIN } from "shared/constants/generic";
import { getLangPrefix } from "shared/helpers/genericHelper";
import "./index.scss"
import logo from "assets/img/waveum-navbar-logo.png"
import { clearLoginState, logoutFx } from "entities/login/model/tLoginFormModel";
import { saveTokenInCookies } from "Widgets/Headar/helpers";
import { BACKOFFICE_BASE_URL } from "utils/constants/navbarPaths";
import { useUnit } from "effector-react";
import { $user } from "entities/user/model";

const OldLoginSidebar = () => {
  const [activeBrands, setActiveBrands] = useState<Record<string, number>>({});

  const user: any = useUnit($user)
  const { userBrandApp, brandApp = BRAND_ID } = user || {}
  const navbarItems = [
    { id: 5, label: i18next.t("storeLabels.storeUI"), icon: faCashRegister, path: '' },
    { id: 9, label: i18next.t("storeLabels.wholesaleUI"), icon: faCartFlatbed, path: '' },
    { id: 22, label: i18next.t("storeLabels.marketplaceUI"), icon: faArrowsToCircle, path: '' },
    { id: 12, label: i18next.t("storeLabels.partnerUI"), icon: faUsers, path: '' }
  ]

  useEffect(() => {
    if (!userBrandApp?.length) return;
    setActiveBrands(userBrandApp.reduce((acc: any, items: any) => {
      acc[items.brandApp] = true;
      return acc;
    }, {}))
  }, [])

  const changeActiveMenu = (id: number) => {
    if (userBrandApp?.length && !activeBrands[id]) return;
    const token = sessionStorage.privateToken;
    saveTokenInCookies(id, token);
  }

  const logoutAllStores = () => {
    logoutFx()
    window.open(`${WAVEUM_LOGIN}${getLangPrefix()}`, "_self")
  }

  const openBackOffice = () => {
    return
    // window.open(`${BACKOFFICE_BASE_URL}`);
  }
  // sessionStorage.setItem("configLang", JSON.stringify(true))
  return (
    <div className="navbarContainer ">
      <Link
        to={getLangPrefix()}
        className="logoContainer"
      >
        <img
          src={logo}
          alt="head-logo"
        />
      </Link>
      <div className="storeIconsContainer">
        {navbarItems.map((item) => (
          <div
            className={`storeIconItem ${!activeBrands?.length || activeBrands?.[item.id] ? 'active' : 'inactive'} ${brandApp === item.id ? 'hovered' : ''}`}
            key={item.id}
            onClick={() => {
              sessionStorage.removeItem("configLang")
              changeActiveMenu(item.id)
            }}
          >
            <FontAwesomeIcon
              color='#153c5d'
              size='2x'
              icon={item.icon}
            />
            <span>{item.label}</span>
          </div>
        ))}
        <div
          className={`storeIconItem inactive hovered`}
          onClick={() => openBackOffice()}
        >
          <FontAwesomeIcon
            color='#153c5d'
            size='2x'
            icon={faServer}
          />
          <span>{i18next.t("storeLabels.backOffice")}</span>
        </div>
      </div>
      <div className='to-bottom'>
        <div
          className="storeIconItem active"
          onClick={logoutAllStores}
        >
          <FontAwesomeIcon
            color='#153c5d'
            size='2x'
            icon={faRightFromBracket}
          />
          <span>{i18next.t('profile.logout')}</span>
        </div>
      </div>
    </div>
  )
}

export default OldLoginSidebar;