import ReactDOM from 'react-dom/client';
import './index.scss';
import App from 'app';
import reportWebVitals from './reportWebVitals';
import 'shared/configs/i18nextConfig'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

reportWebVitals();
