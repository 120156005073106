import { createEffect } from 'effector';
import { IPasswordForm, clearPassForm } from 'entities/passwordForm/model';
import { fetchChangePassword } from 'shared/api/userApi';

export const sendPasswordFX = createEffect(
    async (params: {
        currentPassword: IPasswordForm['currentPassword'];
        newPassword: IPasswordForm['newPassword'];
    }) => {
        try {
            const data = await fetchChangePassword(params);
            if (data && data.status === 'FAILURE') {
                throw new Error(data.message);
            }
            return data;
        } catch (err: unknown) {
            if (err instanceof Error) {
                throw new Error(err.message);
            }
        }
        finally {
            clearPassForm();
        }
    },
);
