import { createEffect } from "effector"
import { IFetchProductsEcommerce, IFetchProductsFilter, fetchFilteredProductsBy, fetchProductsFilter } from "shared/api/productsApi"

export const getProductsFilterByCategoryFx = createEffect(async ({ filterBy, seasonId, language }: IFetchProductsFilter) => {
  try {
    const filters = await fetchProductsFilter({ filterBy, seasonId, language })

    return { [filterBy]: filters }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
})

export const getFilteredProductsFx = createEffect(async (body: IFetchProductsEcommerce) => {
  try {
    const products = await fetchFilteredProductsBy(body)
    return products
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
})
