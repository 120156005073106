import { LoaderPageImageStyles, LoaderPageStyles } from "./style";
import Logo from 'assets/images/logo-main.png'
import { sessionStorageSync } from "shared/helpers/storageHelper";

const LoaderPage = () => {
	if (sessionStorageSync.privateToken) {
		return null
	}
	return (
		<div className={LoaderPageStyles}>
			<img src={Logo} alt="Logo" className={LoaderPageImageStyles} />
		</div>
	);
};
export default LoaderPage;