import { createStore, createEvent } from 'effector';
import { menuHardcodes } from 'shared/constants/routes';
import { RouteStoreItem } from 'shared/hooks/useGenerateRouter';

interface MenuState {
	menu: any;
	error: any;
}

export const $menuState = createStore<MenuState>({
	menu: menuHardcodes,
	error: '',
});

export const updatePaths = createEvent<Record<string, RouteStoreItem>>();

export const $menuPaths = createStore<Record<string, RouteStoreItem>>({});

$menuPaths.on(updatePaths, (_, newpaths) => newpaths);
