/* eslint-disable */
import React from "react";
import LoaderPage from "shared/ui/LoaderPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useGenerateRoutes } from "shared/hooks/useGenerateRouter";
import { useUnit } from "effector-react";
import { $menuState } from "entities/menu/model/menu";

export const Routing = React.memo(() => {
  const { menu } = useUnit($menuState);
  const { defaultRoutes } = useGenerateRoutes(menu);
  if (!defaultRoutes.length) return <LoaderPage />
  const router = createBrowserRouter(defaultRoutes);
  return (
    <RouterProvider router={router} />
  );
});
